import { useRef } from "preact/hooks";
import { delay, notificationMusic } from "~/utils";
import { getLocalAccessToken } from "~/utils/storage";
import { useBotStore, useUserStore } from "~/stores";
import { MessageType, ResponseAction } from "~/types";
import { getConversationId, getRealPayloadArray, packMessageItem } from "./utils";

const iframe = document.createElement("iframe");
iframe.style.display = "none";
document.body.appendChild(iframe);

const nativeFetch: typeof fetch = (...args) => {
  const _nativeFetch =
    iframe.contentWindow?.fetch && window.fetch.toString() !== iframe.contentWindow.fetch.toString()
      ? iframe.contentWindow.fetch
      : window.fetch;

  return _nativeFetch(...args).then((res) => {
    if (res.status >= 200 && res.status < 300) {
      return res;
    }

    return res.json().then((data) => Promise.reject({ ...data, status: res.status }));
  });
};

export enum ChatType {
  CHAT = "CHAT",
  COMPARASION = "COMPARASION",
}

interface IProps {
  pipeMessage: (item: any) => void;
}

interface IChatParams {
  type?: "CHAT" | "COMPARASION";
  text?: string;
  markDone?: () => void; // 标记当前请求已经完成
  onSuccess?: (value: unknown) => void;
  onError?: (value: unknown) => void;
}

interface IErrorResponse {
  status: number;
  error: {
    code: string;
    message: string;
  };
}

const origin = `https://${import.meta.env.VITE_APP_DOMAIN}`;

export default function useChat({ pipeMessage }: IProps) {
  const {
    info: { agentId, tenantUnitId, tenantId },
    isNotificationSoundMuted,
  } = useUserStore();
  const { compareData, setCompareData, getBotCode } = useBotStore();
  const botCode = getBotCode();
  const aborter = useRef(new AbortController());

  async function startChatCall({
    type = ChatType.CHAT,
    text,
    markDone,
    onSuccess,
    onError,
  }: IChatParams) {
    aborter.current?.abort("outdate request"); // cancel previous request
    aborter.current = new AbortController();
    const conversationId = await getConversationId(botCode);
    // chat 和 comparison 是两种不同的消息类型，对应的接口调用不一样，但是结果的流式返回和解析是一样的
    const url =
      type === ChatType.CHAT ? `${origin}/ai/api/v1/scene` : `${origin}/ai/api/v1/compare`;
    const prompt =
      type === ChatType.CHAT ? text : compareData.map((item) => item.product_id).join(",");
    // TODO add context from url, need to refine other methods for delivering context object
    const context =
      type === ChatType.CHAT
        ? Object.fromEntries(
            Array.from(new URLSearchParams(window.location.search)).filter(([key]) =>
              /^[a-z]+([A-Z][a-z]*)*$/.test(key)
            ) // Filter keys that are camelCase or single words
          )
        : undefined;
    const requestOptions: RequestInit = {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${getLocalAccessToken(botCode)}`,
      },
      signal: aborter.current.signal,
      redirect: "follow",
      body: JSON.stringify({
        tenantUnitId,
        tenantId,
        agentId,
        conversationId,
        prompt,
        functionsConfig: {
          contextCrossConversations: "true",
        },
        context,
      }),
    };

    if (type === ChatType.COMPARASION) {
      // 清空 compareData
      setCompareData([]);
    }

    let played = false;

    const readEventSteam = async (
      readableStream: ReadableStream,
      validate?: (payload: any) => boolean
    ) => {
      const reader = readableStream.getReader();
      const decoder = new TextDecoder();
      let messageType = MessageType.TEXT;
      let complatedText = "";

      // 包装逻辑到异步函数中
      const processPayload = async (payloadArray: string[], decoded: string) => {
        for (const str of payloadArray) {
          try {
            const parsed = JSON.parse(str);
            if (isMultipleLoadingStepsArray(payloadArray)) {
              await delay();
            }
            if (validate?.(parsed)) {
              continue;
            }
            if (
              parsed.event === ResponseAction.PARTIAL_ANSWER &&
              !isNotificationSoundMuted &&
              !played
            ) {
              notificationMusic.play();
              played = true;
            }
            const message = packMessageItem(parsed);
            messageType = message.type;
            // 插入异步的控制
            pipeMessage(message);
          } catch (err) {
            /**
             * 此处解析错误可能得场景是:
             * 1、逃逸符: 类似 \n 这种无法在 json 字符串正常解析，目前 server 已经将其转化为 \\n
             * 2、stream 被截取了，一段长文本，被截断成多端，这样就得等到链接完全吐出来之后，将这些字符拼接起来统一解析
             */
            complatedText += decoded;
            console.log("非法 JSON 字符串", err, (err as SyntaxError)?.message, "\n", str);
          }
        }
      };

      while (true) {
        const { done, value } = await reader.read();
        const decoded = decoder.decode(value, { stream: true });
        let realPayloadStringArray = getRealPayloadArray(decoded);

        await processPayload(realPayloadStringArray, decoded);

        if (done) {
          if (complatedText) {
            console.log("complatedText", complatedText);
            const parsed = JSON.parse(complatedText.substring(6));
            const item = packMessageItem(parsed);
            pipeMessage(item);
          }

          break;
        }
      }

      return messageType;
    };

    try {
      const response = await nativeFetch(url, requestOptions);

      await readEventSteam(response.body!);

      markDone?.();
      onSuccess?.(null);

      if (type === ChatType.CHAT) {
        try {
          const response = await nativeFetch(
            `${origin}/ai/api/v1/recommendations/questions`,
            requestOptions
          );
          await readEventSteam(
            response.body!,
            (payload) => payload.type !== MessageType.QUESTION_RECOMMENDR
          );
        } catch (error) {
          // ignore recommend question request error
          console.log(error);
        }
      }
    } catch (err) {
      console.log(err);
      if ((err as IErrorResponse).status) {
        pipeMessage({
          id: Date.now(),
          type: MessageType.RATE_LIMIT_EXCEEDED,
          text: (err as IErrorResponse).error.message,
          done: true,
        });
        markDone?.();
        onSuccess?.(null);
      } else {
        onError?.(err);
      }
    }
  }

  return startChatCall;
}

function isMultipleLoadingStepsArray(data: string[]) {
  return data.filter((item) => (item as string)?.indexOf("prepare_stage") > -1).length > 1;
}
