import { TrackingResponse } from "~/types/express";
import { TenantParams } from "~/types/request";
import request from "~/utils/request";

export const trackingPackage = (
  params: { order?: string; trackingNumber?: string } & TenantParams
): Promise<TrackingResponse> => {
  return request({
    url: "/packages/tracking",
    params,
  });
};
