import { useEffect } from "preact/hooks";
import { reportInstalled } from "~/api/integration";
import { useBotStore, useUserStore } from "~/stores";

export default function useReportBotInstalled() {
  const {
    info: { tenantId, tenantUnitId },
  } = useUserStore();
  const { integrationConfig } = useBotStore();

  useEffect(() => {
    reportInstalled({
      botCode: integrationConfig.code!,
      tenantId,
      tenantUnitId,
      domain: window.location.origin,
    });
  }, []);
}
