import { BotServiceStatus, ISettingMessage } from "~/types";
import request from "~/utils/request";

export const getAgentBotConfig = (
  agentCode: string
): Promise<{
  id: string;
  tenantId: string;
  tenantUnitId: string;
  name: string;
  avatar: string;
  brandColor: string;
  sound?: boolean;
  draggable?: boolean;
  platform?: string;
  initPosition?: {
    left?: string;
    right?: string;
    top?: string;
    bottom?: string;
  };
  recommendQuestions: string[];
  defaultMessageSetting: Partial<ISettingMessage> & {
    placeHolder?: string;
  };
  status: BotServiceStatus;
  customerContact: Record<string, string>;
  logoRemoved: boolean;
}> => request(`/agents/code/${agentCode}`);

// 注意：调用这个接口，会创建一个新访客
export const createUser = (params: { botCode: string; externalUserId?: string }) =>
  request({
    url: `/user/token`,
    params: {
      ...params,
      agentCode: params.botCode,
    },
  });
