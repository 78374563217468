// Bot 使用的前置数据加载
import { usePrevious, useRequest } from "ahooks";
import { useEffect, useState } from "preact/compat";
import { PlatformBrand } from "~/types";
import { getAgentBotConfig, createUser } from "~/api/user";
import { syncShoplineUserInfo } from "~/api/integration";
import {
  addLocalBotCode,
  getLocalAccessToken,
  setLocalAccessToken,
  isNotificationSoundMuted,
} from "~/utils/storage";
import { useBotStore, useCustomizeStore, useUserStore } from "~/stores";
import { Customize } from "~/stores/customize";
import { BotConfigProps, generatePalettes, propsEqual } from "~/utils/widgetConfig";
import { getShoplineUserId } from "~/utils/shopline";

function getToken(params: { botCode: string; externalUserId?: string }) {
  return new Promise(async (resolve, reject) => {
    let localAccessToken = getLocalAccessToken(params.botCode) ?? "";
    let access_token = localAccessToken;

    try {
      if (!localAccessToken) {
        const result = (await createUser(params)) as unknown as { access_token: string };
        access_token = result.access_token;
      }

      setLocalAccessToken(params.botCode, access_token);
      resolve(access_token);
    } catch (error) {
      reject(error);
    }
  });
}

function generateInitPosition(initPosition?: {
  left?: string;
  right?: string;
  top?: string;
  bottom?: string;
}): Partial<Record<"right" | "bottom", number>> | undefined {
  if (!initPosition) {
    return;
  }
  const totalWidth = window.innerWidth;
  const totalHeight = window.innerHeight;
  const bubbleWidth = 60;
  const bubbleHeight = 76;
  const position: Partial<Record<"right" | "bottom", number>> = {};
  if (initPosition.right) {
    const right = Number(initPosition.right?.split("%")[0]);
    if (!isNaN(right) && right >= 0 && right <= 100) {
      position.right = totalWidth * (right / 100);
    }
  }
  if (initPosition.left) {
    const left = Number(initPosition.left?.split("%")[0]);
    if (!isNaN(left) && left >= 0 && left <= 100) {
      position.right = totalWidth - (totalWidth * (left / 100) + bubbleWidth);
    }
  }
  if (initPosition.bottom) {
    const bottom = Number(initPosition.bottom?.split("%")[0]);
    if (!isNaN(bottom) && bottom >= 0 && bottom <= 100) {
      position.bottom = totalHeight * (bottom / 100) + bubbleHeight;
    }
  }
  if (initPosition.top) {
    const top = Number(initPosition.top?.split("%")[0]);
    if (!isNaN(top) && top >= 0 && top <= 100) {
      position.bottom = totalHeight - totalHeight * (top / 100);
    }
  }

  return position;
}

export default function useAliasData(props: BotConfigProps) {
  console.log("3333", { ...props });
  const { code, externalUserId } = props;
  const prevProps = usePrevious(props);

  const [error, setError] = useState(false);
  const [loading, setLoading] = useState(true);
  const [tokenReady, setTokenReady] = useState(false);
  const { customize, updateCustomize } = useCustomizeStore();
  const { updateInfo: updateUserInfo, muteNotificationSoundByRemote } = useUserStore();
  const {
    rootRefresh,
    integrationConfig,
    setRootRefresh,
    resetBotVisibleInWidgetMode,
    resetIntegrationConfig,
  } = useBotStore();

  useEffect(() => {
    if (rootRefresh) {
      resetBotVisibleInWidgetMode();
      resetIntegrationConfig();
    }
  }, [rootRefresh]);

  // TODO:如果这里的 code 不对，要怎么处理？
  useRequest(() => getToken({ botCode: code, externalUserId }), {
    onSuccess() {
      setTokenReady(true);
    },
    onError() {
      setError(true);
    },
  });

  useEffect(() => {
    if (propsEqual(prevProps, props)) {
      return;
    }
    if (!loading) {
      const localCustomize: Partial<Customize> = {};
      if (props.avatar) {
        localCustomize.avatar = props.avatar;
      }
      if (props.name) {
        localCustomize.botName = props.name;
      }
      if (props.theme) {
        localCustomize.brandColor = props.theme;
        localCustomize.palettes = generatePalettes(props.theme);
      }
      if (props.welcome) {
        localCustomize.defaultMessageSetting = {
          ...(customize.defaultMessageSetting || {}),
          welcome: props.welcome,
        };
      }
      updateCustomize(localCustomize);
    }
  }, [loading, props, prevProps, customize]);

  useRequest(() => getAgentBotConfig(code), {
    async onSuccess(result) {
      const botConfig = result;
      const brandColor = props.theme || botConfig.brandColor;

      const recommendQuestions = botConfig.recommendQuestions.filter(
        (item: string) => item.trim() !== ""
      );

      if (props.welcome) {
        botConfig.defaultMessageSetting.welcome = props.welcome;
      }

      if (botConfig?.defaultMessageSetting?.placeHolder) {
        botConfig.defaultMessageSetting.placeholder = botConfig.defaultMessageSetting.placeHolder;
      }

      const remoteSoundEffectEnabled = botConfig.sound === null ? true : Boolean(botConfig.sound);

      updateCustomize({
        avatar: props.avatar || botConfig.avatar,
        botName: props.name || botConfig.name,
        palettes: generatePalettes(brandColor),
        brandColor,
        sound: remoteSoundEffectEnabled,
        draggable: botConfig.draggable === null ? true : Boolean(botConfig.draggable),
        initPosition: generateInitPosition(botConfig.initPosition),
        recommendQuestions,
        defaultMessageSetting: botConfig.defaultMessageSetting,
        serviceStatus: botConfig.status,
        customerContact: botConfig.customerContact,
        logoRemoved: botConfig.logoRemoved,
      });

      const tenantId = botConfig.tenantId;

      updateUserInfo({
        tenantId,
        agentId: botConfig.id,
        platform: botConfig.platform,
        tenantUnitId: botConfig.tenantUnitId,
      });

      addLocalBotCode(code);

      muteNotificationSoundByRemote(
        code,
        !remoteSoundEffectEnabled && isNotificationSoundMuted(code)
      );

      // 对接 shopline 用户信息
      if (integrationConfig.platform === PlatformBrand.SHOPLINE) {
        // TODO:调用对应的接口，同步用户信息
        syncShoplineUserInfo({
          botCode: code,
          tenantId,
          externalUserId: getShoplineUserId(),
        });
      }
    },
    onError() {
      setError(true);
    },
    onFinally() {
      setLoading(false);
      setRootRefresh(false);
    },
    ready: tokenReady,
    refreshDeps: [integrationConfig],
  });

  return {
    error,
    loading,
  };
}
