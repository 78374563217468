import Icon from "@ant-design/icons";
import { useRequest } from "ahooks";
import { useRef, useState } from "preact/compat";
import { getLinkPreview } from "~/api/chat";
import linkSVG from "~/assets/icons/link.svg?react";
import { useBotStore, useCustomizeStore } from "~/stores";

import "./index.scss";

interface IProps {
  url: string;
}

interface Preview {
  description: string;
  image: string;
  title: string;
  url: string;
}

export default function LinkPreview({ url }: IProps) {
  const [preview, setPreview] = useState<Preview>();
  const domRef = useRef<HTMLDivElement>(null);
  const {
    customize: { brandColor },
  } = useCustomizeStore();
  const { getBotCode } = useBotStore();

  useRequest(() => getLinkPreview(getBotCode(), url), {
    onSuccess(data) {
      let { description, image, title, url } = data as unknown as Preview;

      setPreview({
        description,
        image,
        title,
        url,
      });
    },
  });

  function clickLink() {
    window.open(preview?.url);
  }

  if (!preview?.url) return null;

  // 设置图片高度为父元素的 9/16
  function getImageHeight() {
    if (!domRef.current) return;
    const parent = domRef.current as HTMLElement;
    const parentHeight = parent.clientHeight;
    return (parentHeight * 9) / 16 + "px";
  }

  return (
    <div className="link-preview-box" ref={domRef}>
      {preview?.image && <img src={preview?.image} alt="" style={{ height: getImageHeight() }} />}
      <div className="content">
        <div className="title">{preview?.title}</div>
        <div className="desc">{preview?.description}</div>

        <div className="link" onClick={clickLink} style={{ backgroundColor: brandColor }}>
          <span>View Details</span>
          <Icon component={linkSVG} />
        </div>
      </div>
    </div>
  );
}
